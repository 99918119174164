import { jarallax, jarallaxElement, jarallaxVideo } from 'jarallax';
import 'jarallax/src/jarallax.css';

$(document).ready(() => {
    jarallaxVideo();
    jarallaxElement();

    const jarallaxElements = document.querySelectorAll('[data-jarallax]');

    if (!jarallaxElements) {
        return;
    }

    jarallaxElements.forEach((element) => {
        let options = {};

        for (const key in element.dataset) {
            if (!element.dataset[key]) {
                continue;
            }
            const keyWithJarallax = key.replace(/jarallax/, '');
            const jarallaxKey = keyWithJarallax.charAt(0).toLowerCase() + keyWithJarallax.slice(1);
            options[jarallaxKey] = element.dataset[key];
        }

        jarallax(element, options);
    });
});
