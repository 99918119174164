import './modules/aos';
import './modules/foundation';
import './modules/sticky';
import './modules/objectFitPollyfill';
import './modules/owlCarousels';
import './modules/magnificPopups';
import './modules/scroll';
import './modules/header';
import './modules/jarallax';

// Making jQuery available outside of webpack
window['jQuery'] = jQuery;
window['$'] = jQuery;
