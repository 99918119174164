const headerClass = () => {

    const scroll = $(window).scrollTop();
    if (scroll <= 100) {
        $('header').removeClass('header-scrolled');
    } else {
        $('header').addClass('header-scrolled');
    }
};

$(window).resize(headerClass);
$(window).scroll(headerClass);

headerClass();

$(document).ready(() => {
  $('.menu-button').click(function() {
      $(".menu-wrap").toggleClass("menu-open");
      $(".hamburger").toggleClass("ham-open");
      $("#menu").toggleClass("open");
      $(".menu-button p.close-text").toggle();
      $(".menu-button p.menu-text").toggle();
    });
});
